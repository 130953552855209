import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { withRouter, Link,useHistory } from "react-router-dom"

import jwtDecode from "jwt-decode";
// users
import profileImage from "../../../assets/images/profileImage.jpg"

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  const [username, setusername] = useState("Admin")
  const [currentUser, setcurrentUser] = useState({
    name:""
  })
  useEffect(() => {
    
    // if (localStorage.getItem("authUser")) {
    //   if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
    //     const obj = JSON.parse(localStorage.getItem("authUser"))
    //     setusername(obj.displayName)
    //   } else if (
    //     process.env.REACT_APP_DEFAULTAUTH === "fake" ||
    //     process.env.REACT_APP_DEFAULTAUTH === "jwt"
    //   ) {
    //     const obj = JSON.parse(localStorage.getItem("authUser"))
    //     setusername(obj.username)
    //   }
    // }
    const token = localStorage.getItem("token");
    if(token){
    const user = jwtDecode(token);
    if(user){
      setcurrentUser(user)
    }
  }
    // console.log("user",user)
  }, [props.success])
  const history = useHistory();
  
  const handleLogOut=()=>{
    sessionStorage.removeItem("token")
    localStorage.removeItem("token")

    sessionStorage.removeItem("studentToken")
    localStorage.removeItem("studentToken")
    history.push(`/login`) 
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={profileImage}
            alt="Header Avatar"
          />  
          {" "}<span >{currentUser?.name}</span>
            {" "}<span className="mdi mdi-chevron-down"></span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href="/profile">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("Profile")}{" "}
          </DropdownItem>
          {/* <DropdownItem tag="a" href="auth-lock-screen">
            <i className="bx bx-lock-open font-size-16 align-middle me-1" />
            {props.t("Lock screen")}
          </DropdownItem> */}
          <div className="dropdown-divider" />
          <Link onClick={()=>handleLogOut()} className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
